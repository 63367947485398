import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'

const toolData = {
    stage: 0,
    isOrbitMode: false,
    isShiftDown: false,
    point0: new THREE.Vector3,
    point1: new THREE.Vector3,
    point2: new THREE.Vector3,
    drawingLine: new THREE.Line,
    drawingArc: new THREE.Line,
    arcCenter: new THREE.Vector3,
    arcAxis1: new THREE.Vector3,
    arcAxis2: new THREE.Vector3,
    arcAngle: 0.0,
    arcStartAngle: 0.0,
    nArcSegments: 24,
    nFilletSegments: 16,
    filletRadius: 32,
    filletOffset: 32,
    arcPoints: [],
    bigArcPoints: [],
    filletPoints: [],
    cylinderMesh: null,
    cylinderHeight: 1,
    plane: null,
    planeMesh: null,
    planeD: 0.5,
    planeTiltX: 0,
    planeTiltZ: 0,
    mouseX: 0,
    mouseY: 0,
    useNormalMaterial: false,
    smoothMode: false,
    previousPoint0: new THREE.Vector3,
    previousPoint1: new THREE.Vector3,
    previousPoint2: new THREE.Vector3
};

const objects = [];

// GUI
const gui = new GUI()


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color( 0xf0f0f0 );

// lines
const lineMaterial = new THREE.LineBasicMaterial( { color: 0x000000 } );

// grid
const gridHelper = new THREE.GridHelper( 1000, 20 );
scene.add( gridHelper );

//

let raycaster = new THREE.Raycaster();
let pointer = new THREE.Vector2();

const planeGeometry = new THREE.PlaneGeometry( 1000, 1000 );
planeGeometry.rotateX( - Math.PI / 2 );

let plane = new THREE.Mesh( planeGeometry, new THREE.MeshPhongMaterial( { visible: false } ) );
scene.add( plane );

objects.push( plane );


// Camera
const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 10000 );
camera.position.set( 500, 800, 1300 );
camera.lookAt( 0, 0, 0 );
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
//controls.enableDamping = true
controls.enabled = false;
//gui.add(toolData, 'isOrbitMode').name( 'Orbit Mode' ).onChange( value => {
//    controls.enabled = toolData.isOrbitMode } )
gui.add(toolData, 'smoothMode').onChange( regenerateFilletedCylinderAndPlane ).name('Smooth Mode');
gui.add(toolData, 'useNormalMaterial').onChange( regenerateFilletedCylinderAndPlane ).name('Normal Map');
gui.add(toolData, 'nArcSegments', 3, 180, 1 ).onChange( regenerateFilletedCylinder ).name('# Arc Segments').listen();
gui.add(toolData, 'nFilletSegments', 1, 48, 1 ).onChange( regenerateFilletedCylinder ).name('# Fillet Segments').listen();
gui.add(toolData, 'filletOffset', 1, 120, 1 ).onChange( regenerateFilletedCylinderAndPlane ).name('Fillet Offset');
gui.add(toolData, 'cylinderHeight', 1, 2000, 1 ).onChange( regenerateFilletedCylinderAndPlane ).name('Height').listen();
gui.add(toolData, 'planeTiltX', -0.9, 0.9, 0.01 ).onChange( regenerateFilletedCylinderAndPlane ).name('X Tilt').listen();
gui.add(toolData, 'planeTiltZ', -0.9, 0.9, 0.01 ).onChange( regenerateFilletedCylinderAndPlane ).name('Z Tilt').listen();

const ambientLight = new THREE.AmbientLight( 0x606060 );
scene.add( ambientLight );

const directionalLight = new THREE.DirectionalLight( 0xffffff );
directionalLight.position.set( 1, 0.75, 0.5 ).normalize();
scene.add( directionalLight );

// Renderer
let renderer = new THREE.WebGLRenderer( { antialias: true, canvas: canvas } );
renderer.setPixelRatio( window.devicePixelRatio );
renderer.setSize( window.innerWidth, window.innerHeight );
document.body.appendChild( renderer.domElement );

document.addEventListener( 'pointermove', onPointerMove );
document.addEventListener( 'pointerdown', onPointerDown );
document.addEventListener( 'keydown', onDocumentKeyDown );
document.addEventListener( 'keyup', onDocumentKeyUp );
document.addEventListener( 'wheel', onWheelMove );

//

window.addEventListener( 'resize', onWindowResize );

render();


function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

    render();

}

function onWheelMove( event) {
    if (toolData.isOrbitMode)
    {
        render();
    }
}

function onPointerMove( event ) {

    if (toolData.isOrbitMode)
    {
        render();
    }
    else
    {
        pointer.set( ( event.clientX / window.innerWidth ) * 2 - 1, - ( event.clientY / window.innerHeight ) * 2 + 1 );

        raycaster.setFromCamera( pointer, camera );

        const intersects = raycaster.intersectObjects( objects, false );

        let intersect = null;
        if ( intersects.length > 0 ) {

            intersect = intersects[ 0 ];
        }

        if (toolData.stage == 1 && intersect != null)
        {
            scene.remove(toolData.drawingLine);
            toolData.point1 = intersect.point;

            const points = [];
            points.push( toolData.point0 );
            points.push( toolData.point1 );
            
            const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );
            toolData.drawingLine = new THREE.Line( lineGeometry, lineMaterial );
            scene.add( toolData.drawingLine );

            render();
        }
        else if (toolData.stage == 2 && intersect != null)
        {
            scene.remove(toolData.drawingArc);
            toolData.point2 = intersect.point;

            calculateArcPoints();

            if (toolData.arcPoints.length < 3)
            {
                const points = [];
                points.push( toolData.point1 );
                points.push( toolData.point2 );
            
                const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );
                toolData.drawingArc = new THREE.Line( lineGeometry, lineMaterial );
            }
            else
            {
                const arcGeometry = new THREE.BufferGeometry().setFromPoints( toolData.arcPoints );
        
                const arcMaterial = new THREE.LineBasicMaterial( { color: 0x000000 } );
            
                const arcLine = new THREE.Line( arcGeometry, arcMaterial );
                toolData.drawingArc = arcLine;
            }            
            scene.add( toolData.drawingArc );

            render();
        }
        else if (toolData.stage == 3)
        {
            let dy = 5 * (toolData.mouseY - event.clientY); 

            if (dy < 1)
            {
                dy = 1;
            }
                    
            scene.remove(toolData.cylinderMesh);
            toolData.cylinderHeight = dy;
            createCylinderMesh();               

            render()
        }
        else if (toolData.stage == 4)
        {
            let dx = 0.0025 * (event.clientX - toolData.mouseX); 
            let dy = 0.0025 * (event.clientY - toolData.mouseY); 
            toolData.mouseX = event.clientX;
            toolData.mouseY = event.clientY;

            const tiltLimit = 0.9;

            toolData.planeTiltX += dx
            if (toolData.planeTiltX < -tiltLimit)
            {
                toolData.planeTiltX = -tiltLimit;
            }
            if (toolData.planeTiltX > tiltLimit)
            {
                toolData.planeTiltX = tiltLimit;
            }
             
            toolData.planeTiltZ += dy
            if (toolData.planeTiltZ < -tiltLimit)
            {
                toolData.planeTiltZ = -tiltLimit;
            }
            if (toolData.planeTiltZ > tiltLimit)
            {
                toolData.planeTiltZ = tiltLimit;
            }

            scene.remove(toolData.planeMesh);
            createPlaneMesh();

            render()
        }
    }
}

function onPointerDown( event ) {

    if (toolData.isOrbitMode)
    {
        render();
    }
    else
    {
        pointer.set( ( event.clientX / window.innerWidth ) * 2 - 1, - ( event.clientY / window.innerHeight ) * 2 + 1 );

        raycaster.setFromCamera( pointer, camera );

        toolData.mouseX =  event.clientX;
        toolData.mouseY =  event.clientY;

        const intersects = raycaster.intersectObjects( objects, false );
        let intersect = null;

        if ( intersects.length > 0 ) {

            intersect = intersects[ 0 ];
        }

        if ( toolData.isShiftDown && intersect != null) {

            // delete object

            if ( intersect.object !== plane ) {

                scene.remove( intersect.object );

                objects.splice( objects.indexOf( intersect.object ), 1 );
            }
        }    
        else if (toolData.stage == 0 && intersect != null)
        {
            // first arc point
            toolData.previousPoint0.copy(toolData.point0);
            toolData.previousPoint1.copy(toolData.point1);
            toolData.previousPoint2.copy(toolData.point2);

            toolData.point0 = intersect.point;
            toolData.point1 = intersect.point;

            const points = [];
            points.push( toolData.point0 );
            points.push( toolData.point1 );
            
            const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );
            toolData.drawingLine = new THREE.Line( lineGeometry, lineMaterial );
            scene.add( toolData.drawingLine );

            toolData.stage = 1;
        }
        else if (toolData.stage == 1 && intersect != null)
        {
            // second arc point
            toolData.point1 = intersect.point;
            toolData.point2 = intersect.point;

            const points = [];
            points.push( toolData.point1 );
            points.push( toolData.point2 );
            
            const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );
            toolData.drawingArc = new THREE.Line( lineGeometry, lineMaterial );
            scene.add( toolData.drawingArc );

            toolData.stage = 2;
        }
        else if (toolData.stage == 2 && intersect != null)
        {
            // third arc point
            scene.remove(toolData.drawingLine);
            scene.remove(toolData.drawingArc);
            calculateArcPoints();

            if (toolData.arcPoints.length > 2)
            {
                toolData.cylinderHeight = 1;

                createCylinderMesh();

                toolData.stage = 3;
            }
            else
            {
                toolData.stage = 0;
            }
        }
        else if (toolData.stage == 3)
        {
            // position plane
            toolData.planeD = 0.5;
            createPlaneMesh();

            toolData.stage = 4;
        }
        else if (toolData.stage == 4)
        {
            scene.remove(toolData.cylinderMesh);
            scene.remove(toolData.planeMesh);

            createPlaneMesh(false);            
            createFilletedCylinderMesh();               

            toolData.stage = 0;
        }               
        render();
    }
}

function onDocumentKeyDown( event ) {

    switch ( event.keyCode ) {

        case 16: toolData.isShiftDown = true; break;    // Shift
        case 27: 
            // Esc
            if (toolData.stage > 2)
            {
                scene.remove(toolData.cylinderMesh);
                scene.remove(toolData.planeMesh);
            }   
            if (toolData.stage > 1)
            {            
                scene.remove(toolData.drawingArc);
            }
            if (toolData.stage > 0)
            {
                toolData.point0.copy(toolData.previousPoint0);
                toolData.point1.copy(toolData.previousPoint1);
                toolData.point2.copy(toolData.previousPoint2);
                scene.remove(toolData.drawingLine);
            }
            toolData.stage = 0;
            render();
            break;
        case 32: toolData.isOrbitMode = true;controls.enabled = true; break; // Space bar
        case 46: 
            // Del
            scene.remove(toolData.drawingLine);
            scene.remove(toolData.drawingArc);
            scene.remove(toolData.cylinderMesh);
            scene.remove(toolData.planeMesh);
            toolData.stage = 0;
            render();
            break;
    }

}

function onDocumentKeyUp( event ) {

    switch ( event.keyCode ) {

        case 16: toolData.isShiftDown = false; break;
        case 32: toolData.isOrbitMode = false;controls.enabled = false; break;
    }

}

function render() {

    renderer.render( scene, camera );
}

function regenerateFilletedCylinderAndPlane()
{
    if (toolData.stage == 0 && toolData.cylinderMesh != null)
    {
        scene.remove(toolData.cylinderMesh);
        scene.remove(toolData.planeMesh);

        calculateArcPoints();
        createPlaneMesh(false);
        createFilletedCylinderMesh();

        render();
    }
}
function regenerateFilletedCylinder()
{
    if (toolData.stage == 0 && toolData.cylinderMesh != null)
    {
        scene.remove(toolData.cylinderMesh);

        calculateArcPoints();
        createFilletedCylinderMesh();

        render();
    }
}

function calculateArcPoints() 
{
	toolData.arcPoints.length = 0;
    toolData.bigArcPoints.length = 0;
    toolData.arcRadius = 0.0;
    toolData.arcCenter.x = 0.0;
    toolData.arcCenter.y = 0.0;
    toolData.arcCenter.z = 0.0;
    toolData.arcAngle = 0.0;
	
	// get 2D basis vectors
    let len1 = toolData.point2.distanceTo(toolData.point0);
    let len2 = toolData.point2.distanceTo(toolData.point1);
    let v1 = new THREE.Vector3(toolData.point0.x, toolData.point0.y, toolData.point0.z);
    v1.sub(toolData.point2);
    v1.normalize();
    let v2 = new THREE.Vector3(toolData.point1.x, toolData.point1.y, toolData.point1.z);
    v2.sub(toolData.point2);
    let a = v1.dot(v2);

    // make sure radius won't be too large or infinite
    if (Math.abs(a) < 0.999999 * len2)
    {
        // orthogonalize v1, v2
        v2.x = v2.x - a * v1.x;
        v2.y = v2.y - a * v1.y;
        v2.z = v2.z - a * v1.z;
        v2.normalize();

        let midPoint = new THREE.Vector3(toolData.point0.x, toolData.point0.y, toolData.point0.z);
        midPoint.add(toolData.point1);
        midPoint.multiplyScalar(0.5);
        let flatDistance = toolData.point0.distanceTo(toolData.point1);
        let hDistance = toolData.point2.distanceTo(midPoint);

        let bx = len1;
        let by = 0.0;
        let cx = a;
        let cy = Math.sqrt(len2*len2 - a*a)
        let dx = 0.0;
        let dy = 0.0;

        let temp = cx*cx + cy*cy
        let bc = (bx*bx + by*by - temp) / 2
        let cd = (temp - dx*dx - dy*dy) / 2
        let det = (bx - cx) * (cy - dy) - (cx - dx) * (by - cy)

        if (Math.abs(det) > 0.0000000001)
        {        
            // Center of circle
            let centerX = (bc*(cy - dy) - cd*(by - cy)) / det
            let centerY = ((bx - cx) * cd - (cx - dx) * bc) / det

            toolData.arcCenter.x = toolData.point2.x + centerX * v1.x + centerY * v2.x;
            toolData.arcCenter.y = toolData.point2.y + centerX * v1.y + centerY * v2.y;
            toolData.arcCenter.z = toolData.point2.z + centerX * v1.z + centerY * v2.z;

            // Radius
            let r = Math.sqrt((centerX - bx)*(centerX - bx) + (centerY - by)*(centerY - by));
            toolData.arcRadius = r;

            // arc points
            let x0 = bx - centerX;
            let y0 = by - centerY;
            let x1 = cx - centerX;
            let y1 = cy - centerY;
            let x2 = dx - centerX;
            let y2 = dy - centerY;

            let angle0 = Math.atan2(y0,x0);
            let angle1 = Math.atan2(y1,x1);
            //let angle2 = Math.atan2(y2,x2);
            let angle = Math.abs(angle1 - angle0);
            let startAngle = angle1;
            if (hDistance > 0.5 * flatDistance)
            {
                if (angle < Math.PI)
                {
                    angle = 2 * Math.PI - angle;
                }
            }
            else if(hDistance < 0.5 * flatDistance)
            {
                if (angle > Math.PI)
                {
                    angle = 2 * Math.PI - angle;
                }
            }
            toolData.arcStartAngle = startAngle;
            toolData.arcAngle = angle;

            //if (toolData.smoothMode == true)
            //{
                //toolData.nArcSegments = 180;
            //}

            if (toolData.nArcSegments > 0)
            {
                let da = toolData.arcAngle / toolData.nArcSegments;
                let bigScale = 1.5;
                for (let i = 0, a = startAngle; i <= toolData.nArcSegments; i++, a += da) 
                {
                    // inner arc point
                    let x2D = centerX + r * Math.cos(a);
                    let y2D = centerY + r * Math.sin(a);

                    let x3D = toolData.point2.x + x2D * v1.x + y2D * v2.x;
                    let y3D = toolData.point2.y + x2D * v1.y + y2D * v2.y;
                    let z3D = toolData.point2.z + x2D * v1.z + y2D * v2.z;

                    toolData.arcPoints.push(new THREE.Vector3( x3D, y3D, z3D));

                    // outer arc point
                    x2D = centerX + bigScale * r * Math.cos(a);
                    y2D = centerY + bigScale * r * Math.sin(a);

                    x3D = toolData.point2.x + x2D * v1.x + y2D * v2.x;
                    y3D = toolData.point2.y + x2D * v1.y + y2D * v2.y;
                    z3D = toolData.point2.z + x2D * v1.z + y2D * v2.z;

                    toolData.bigArcPoints.push(new THREE.Vector3( x3D, y3D, z3D));                    
                }     
                
                // adjust first and last outer arc points
                if (toolData.arcPoints.length > 1)
                {
                    const n = toolData.arcPoints.length;
                    const p0 = toolData.arcPoints[0];
                    const p1 = toolData.arcPoints[1];
                    const pn1 = toolData.arcPoints[n-1];
                    const pn2 = toolData.arcPoints[n-2];
                    const v1 = new THREE.Vector3(p1.x - p0.x, p1.y - p0.y, p1.z - p0.z);
                    v1.normalize();
                    const v2 = new THREE.Vector3(pn1.x - p0.x, pn1.y - p0.y, pn1.z - p0.z);
                    v2.normalize();
                    const vMid = new THREE.Vector3(v1.x + v2.x, v1.y + v2.y, v1.z + v2.z);
                    vMid.normalize();

                    toolData.bigArcPoints[0].x = p0.x - 0.5 * r * vMid.x;
                    toolData.bigArcPoints[0].y = p0.y - 0.5 * r * vMid.y;
                    toolData.bigArcPoints[0].z = p0.z - 0.5 * r * vMid.z;

                    v2.multiplyScalar(-1);
                    const v3 = new THREE.Vector3(pn2.x - pn1.x, pn2.y - pn1.y, pn2.z - pn1.z);
                    v3.normalize();
                    const vMid2 = new THREE.Vector3(v2.x + v3.x, v2.y + v3.y, v2.z + v3.z);
                    vMid2.normalize();

                    toolData.bigArcPoints[n-1].x = pn1.x - 0.5 * r * vMid2.x;
                    toolData.bigArcPoints[n-1].y = pn1.y - 0.5 * r * vMid2.y;
                    toolData.bigArcPoints[n-1].z = pn1.z - 0.5 * r * vMid2.z;
                }

                // see if order of points needs to be reversed
                if (toolData.arcPoints.length > 1)
                {
                    const va = new THREE.Vector3(toolData.arcPoints[0].x, toolData.arcPoints[0].y, toolData.arcPoints[0].z);
                    va.sub(toolData.arcCenter);
                    const vb = new THREE.Vector3(toolData.arcPoints[1].x, toolData.arcPoints[1].y, toolData.arcPoints[1].z);
                    vb.sub(toolData.arcCenter);
                    const vc = new THREE.Vector3();
                    vc.crossVectors(va,vb);
                    if (vc.y < 0)
                    {
                        toolData.arcPoints.reverse();
                        toolData.bigArcPoints.reverse();
                    }
                }
             }
                        
            // axes
            toolData.arcAxis1.x = v1.x;
            toolData.arcAxis1.y = v1.y;
            toolData.arcAxis1.z = v1.z;        
            toolData.arcAxis2.x = v2.x;
            toolData.arcAxis2.y = v2.y;
            toolData.arcAxis2.z = v2.z;
        }
    }
}

function createCylinderMesh(ghostly = true)
{
    if (toolData.arcPoints.length > 2)
    {
        let n = toolData.arcPoints.length;
        let h = toolData.cylinderHeight;
        let x3 = toolData.arcPoints[n-1].x;
        let y3 = toolData.arcPoints[n-1].y;
        let z3 = toolData.arcPoints[n-1].z;

        const meshPoints = [];
        for (let i = 0;i < n;i++)
        {
            // 2 triangles for each quad face
            let x1 = toolData.arcPoints[i].x;
            let y1 = toolData.arcPoints[i].y;
            let z1 = toolData.arcPoints[i].z;
            let x2 = 0.0;
            let y2 = 0.0;
            let z2 = 0.0;
            if (i == n-1)
            {
                x2 = toolData.arcPoints[0].x;
                y2 = toolData.arcPoints[0].y;
                z2 = toolData.arcPoints[0].z;
            }
            else
            {
                x2 = toolData.arcPoints[i+1].x;
                y2 = toolData.arcPoints[i+1].y;
                z2 = toolData.arcPoints[i+1].z;
            }

            // side face
            meshPoints.push(new THREE.Vector3(x1, y1, z1));
            meshPoints.push(new THREE.Vector3(x2, y2, z2));
            meshPoints.push(new THREE.Vector3(x2, y2 + h, z2));

            meshPoints.push(new THREE.Vector3(x1, y1, z1));
            meshPoints.push(new THREE.Vector3(x2, y2 + h, z2));
            meshPoints.push(new THREE.Vector3(x1, y1 + h, z1));

            // bottom and top face
            if (i < n-1)
            {
                meshPoints.push(new THREE.Vector3(x1, y1, z1));
                meshPoints.push(new THREE.Vector3(x3, y3, z3));
                meshPoints.push(new THREE.Vector3(x2, y2, z2));

                meshPoints.push(new THREE.Vector3(x1, y1 + h, z1));
                meshPoints.push(new THREE.Vector3(x2, y2 + h, z2));
                meshPoints.push(new THREE.Vector3(x3, y3 + h, z3));
            }
        }

		let customMaterial;
        
        if (ghostly)
        {
            customMaterial = new THREE.MeshPhongMaterial( { color: 0xff0000, opacity: 0.5, transparent: true } );
        }
        else if (toolData.useNormalMaterial == true)
        {
            customMaterial = new THREE.MeshNormalMaterial();
        }
        else
        {
            customMaterial = new THREE.MeshPhongMaterial( { color: 0x0000ff } );
        }

        let customGeometry = new THREE.BufferGeometry()
        customGeometry.setFromPoints(meshPoints)
        customGeometry.computeVertexNormals()

        const mesh = new THREE.Mesh(customGeometry, customMaterial)

        if (toolData.smoothMode == false)
        {
            const edges = new THREE.EdgesGeometry( customGeometry );
            const lines = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) );
            mesh.add( lines );        
        }

        scene.add(mesh)             

        toolData.cylinderMesh = mesh;
    }
}

function createPlaneMesh(ghostly = true)
{
    if (toolData.arcPoints.length > 0)
    {
        const h = toolData.planeD * toolData.cylinderHeight;
        const arcX = toolData.arcCenter.x;
        const arcY = toolData.arcCenter.y;
        const arcZ = toolData.arcCenter.z;

        let loX = toolData.arcPoints[0].x;
        let hiX = loX;
        let loZ = toolData.arcPoints[0].z;
        let hiZ = loX;
        let n = toolData.arcPoints.length;
        let cX = loX;
        let cY =  toolData.arcPoints[0].y;
        let cZ = loZ;

        for (let i=1;i<n;i++)
        {
            let x = toolData.arcPoints[i].x;
            let y = toolData.arcPoints[i].y;
            let z = toolData.arcPoints[i].z;
            cX += x;
            cY += y;
            cZ += z;

            if (x < loX)
            {
                loX = x;
            }
            if (x > hiX)
            {
                hiX = x;
            }            
            if (z < loZ)
            {
                loZ = z;
            }
            if (z > hiZ)
            {
                hiZ = z;
            }            
        }
        cX /= n;
        cY /= n;
        cZ /= n;
        let hiSpan = Math.max(hiX - loX, hiZ - loZ);

        const planeNormal = new THREE.Vector3(toolData.planeTiltX, 1, toolData.planeTiltZ);
        planeNormal.normalize();

        const plane = new THREE.Plane( planeNormal, 0 );
        const point = new THREE.Vector3(cX, cY + h, cZ);
        plane.setFromNormalAndCoplanarPoint( planeNormal, point);
        toolData.plane = plane;

        let r = 0.8 * hiSpan + 0.6 * toolData.filletOffset;

        let p0 = new THREE.Vector3(cX - r, cY, cZ - r);
        let p1 = new THREE.Vector3(cX + r, cY, cZ - r);
        let p2 = new THREE.Vector3(cX + r, cY, cZ + r);
        let p3 = new THREE.Vector3(cX - r, cY, cZ + r);
        p0.y -= toolData.plane.distanceToPoint(p0);
        p1.y -= toolData.plane.distanceToPoint(p1);
        p2.y -= toolData.plane.distanceToPoint(p2);
        p3.y -= toolData.plane.distanceToPoint(p3);
        
        const meshPoints = [];

        meshPoints.push(p0);
        meshPoints.push(p1);
        meshPoints.push(p2);

        meshPoints.push(p2);
        meshPoints.push(p3);
        meshPoints.push(p0);

        meshPoints.push(p0);
        meshPoints.push(p2);
        meshPoints.push(p1);

        meshPoints.push(p2);
        meshPoints.push(p0);
        meshPoints.push(p3);

        let customMaterial;

        if (ghostly)
        {
            customMaterial = new THREE.MeshPhongMaterial( { color: 0x00ff00, opacity: 0.5, transparent: true } );
        }
        else if (toolData.useNormalMaterial == true)
        {
            customMaterial = new THREE.MeshNormalMaterial();
        }
        else
        {
            customMaterial = new THREE.MeshPhongMaterial( { color: 0x0000ff } );
        }

        let customGeometry = new THREE.BufferGeometry()
        customGeometry.setFromPoints(meshPoints)
        customGeometry.computeVertexNormals()

        const mesh = new THREE.Mesh(customGeometry, customMaterial);

        if (toolData.smoothMode == false && toolData.useNormalMaterial == false)
        {
            const edges = new THREE.EdgesGeometry( customGeometry );
            const lines = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) );
            mesh.add( lines );        
        }

        toolData.planeMesh = mesh;

        scene.add(mesh)             
    }
}

function calculateSpinePoints(p0, p1, p2, p3)
{
    const spinePnts = [];

    if (toolData.smoothMode == true)
    {
        toolData.nFilletSegments = 48;
    }

    const n = toolData.nFilletSegments;

    // the bottom point
    spinePnts.push(p0);

    const c = toolData.filletOffset;

    const v1 = new THREE.Vector3(p0.x - p1.x, p0.y - p1.y, p0.z - p1.z);
    v1.normalize();
    const v2 = new THREE.Vector3(p2.x - p1.x, p2.y - p1.y, p2.z - p1.z);
    v2.normalize();

     // the lower fillet   
    const fp0 = new THREE.Vector3(p1.x + c * v1.x, p1.y + c * v1.y, p1.z + c * v1.z);
    const fp1 = new THREE.Vector3(p1.x + c * v2.x, p1.y + c * v2.y, p1.z + c * v2.z);
    const fp0_fp1 = new THREE.Vector3(fp0.x + fp1.x, fp0.y + fp1.y, fp0.z + fp1.z);
    fp0_fp1.multiplyScalar(0.5);

    let a = p1.distanceTo(fp0_fp1);

    if (a > 0.0)
    {
        const b = fp0_fp1.distanceTo(fp0);
        const d = c*c/a;
        const r = c*b/a;
        const vMid = new THREE.Vector3(fp0_fp1.x - p1.x, fp0_fp1.y - p1.y, fp0_fp1.z - p1.z);
        vMid.normalize();

        const fCenter = new THREE.Vector3(p1.x + d * vMid.x, p1.y + d * vMid.y, p1.z + d * vMid.z);
        
        const fv0 = new THREE.Vector3(fp0.x - fCenter.x, fp0.y - fCenter.y, fp0.z - fCenter.z);
        const fv1 = new THREE.Vector3(fp1.x - fCenter.x, fp1.y - fCenter.y, fp1.z - fCenter.z);

        spinePnts.push(fp0);

        const da = 1.0 / n;
        for (let i=1, alpha=da;i<n;i++,alpha += da)
        {
            const fv = new THREE.Vector3((1.0-alpha) * fv0.x +  alpha* fv1.x, 
                (1.0-alpha) * fv0.y + alpha * fv1.y, (1.0-alpha) * fv0.z + alpha * fv1.z);
            fv.normalize();

            spinePnts.push(new THREE.Vector3(fCenter.x + r * fv.x, fCenter.y + r * fv.y, fCenter.z + r * fv.z));
        }

        spinePnts.push(fp1);
    }

    // the upper fillet
    const fp2 = new THREE.Vector3(p1.x - c * v1.x, p1.y - c * v1.y, p1.z - c * v1.z);
    const fp2_fp1 = new THREE.Vector3(fp2.x + fp1.x, fp2.y + fp1.y, fp2.z + fp1.z);
    fp2_fp1.multiplyScalar(0.5);

    a = p1.distanceTo(fp2_fp1);

    if (a > 0.0)
    {
        const b = fp2_fp1.distanceTo(fp2);
        const d = c*c/a;
        const r = c*b/a;
        const vMid = new THREE.Vector3(fp2_fp1.x - p1.x, fp2_fp1.y - p1.y, fp2_fp1.z - p1.z);
        vMid.normalize();

        const fCenter = new THREE.Vector3(p1.x + d * vMid.x, p1.y + d * vMid.y, p1.z + d * vMid.z);
        
        const fv0 = new THREE.Vector3(fp1.x - fCenter.x, fp1.y - fCenter.y, fp1.z - fCenter.z);
        const fv1 = new THREE.Vector3(fp2.x - fCenter.x, fp2.y - fCenter.y, fp2.z - fCenter.z);

        const da = 1.0 / n;
        for (let i=1, alpha=da;i<n;i++,alpha += da)
        {
            const fv = new THREE.Vector3((1.0-alpha) * fv0.x +  alpha* fv1.x, 
                (1.0-alpha) * fv0.y + alpha * fv1.y, (1.0-alpha) * fv0.z + alpha * fv1.z);
            fv.normalize();

            spinePnts.push(new THREE.Vector3(fCenter.x + r * fv.x, fCenter.y + r * fv.y, fCenter.z + r * fv.z));
        }

        spinePnts.push(fp2);
    }

    // the top point
    spinePnts.push(p3);


    return spinePnts;
}

function createFilletedCylinderMesh()
{
    if (toolData.arcPoints.length > 2)
    {        
        let n = toolData.arcPoints.length;
        let h = 0;

        // check to see if filleting possible
        for(let i=0;i<n;i++)
        {
            h = 0 - toolData.plane.distanceToPoint(toolData.arcPoints[i]);
            
            if (h < 0.000001)
            {
                // plane does not intersect only column of cylinder
                createCylinderMesh(false);

                return;
            }
        }

        let p0 = toolData.arcPoints[0];
        h = 0 - toolData.plane.distanceToPoint(p0);
        let p1 = new THREE.Vector3(p0.x, p0.y + h, p0.z);
        let bp0 = toolData.bigArcPoints[0];
        h = 0 - toolData.plane.distanceToPoint(bp0);
        let p2 = new THREE.Vector3(bp0.x, bp0.y + h, bp0.z);
        h = toolData.cylinderHeight;
        let p3 = new THREE.Vector3(p0.x, p0.y + h, p0.z);
        const spine0 = calculateSpinePoints(p0, p1, p2, p3);
        const numSpinePnts = spine0.length;
        let spine1 = spine0;
        let spine2 = spine1;
        
        const spineLines = [];
        const meshPoints = [];
        const bottomPoints = [];
        const topPoints = [];
        const upperBandPoints = [];
        const outerEdgePoints = [];
        const lowerBandPoints = [];
        bottomPoints.push(spine0[0]);
        topPoints.push(spine0[numSpinePnts-1]);
        upperBandPoints.push(spine0[numSpinePnts-2]);
        const k = Math.floor(numSpinePnts/2);
        outerEdgePoints.push(spine0[k]);
        lowerBandPoints.push(spine0[1]);

        for (let i = 0;i < n;i++)
        {
            if (i < n-1)
            {
                p0 = toolData.arcPoints[i+1];
                h = 0 - toolData.plane.distanceToPoint(p0);
                p1 = new THREE.Vector3(p0.x, p0.y + h, p0.z);
                bp0 = toolData.bigArcPoints[i+1];
                h = 0 - toolData.plane.distanceToPoint(bp0);
                p2 = new THREE.Vector3(bp0.x, bp0.y + h, bp0.z);
                h = toolData.cylinderHeight;
                p3 = new THREE.Vector3(p0.x, p0.y + h, p0.z);        
                spine2 = calculateSpinePoints(p0, p1, p2, p3);
            }
            else
            {
                spine2 = spine0;
            }

            const spineGeometry = new THREE.BufferGeometry().setFromPoints( spine2 );
            const line = new THREE.Line( spineGeometry, new THREE.LineBasicMaterial( { color: 0x000000 } ));
            spineLines.push(line);

            bottomPoints.push(spine2[0]);
            topPoints.push(spine2[numSpinePnts-1]);
            upperBandPoints.push(spine2[numSpinePnts-2]);
            outerEdgePoints.push(spine2[k]);
            lowerBandPoints.push(spine2[1]);

            for (let j = 0;j < numSpinePnts-1;j ++)
            {
                // 2 triangles for each quad face
                let sp1 = spine1[j];
                let sp2 = spine2[j];
                let sp3 = spine2[j+1];
                let sp4 = spine1[j+1];
                
                // side face
                meshPoints.push(sp1);
                meshPoints.push(sp2);
                meshPoints.push(sp3);

                meshPoints.push(sp1);
                meshPoints.push(sp3);
                meshPoints.push(sp4);
            }

            // bottom and top face
            if (i > 0)
            {
                meshPoints.push(spine1[0]);
                meshPoints.push(spine0[0]);
                meshPoints.push(spine2[0]);

                meshPoints.push(spine1[numSpinePnts-1]);
                meshPoints.push(spine2[numSpinePnts-1]);
                meshPoints.push(spine0[numSpinePnts-1]);
            }
            
            spine1 = spine2;
        }

		let customMaterial;
        if (toolData.useNormalMaterial == true)
        {
            customMaterial = new THREE.MeshNormalMaterial();
        }
        else
        {
            customMaterial = new THREE.MeshPhongMaterial( { color: 0x0000ff } );
        }

        let customGeometry = new THREE.BufferGeometry()
        customGeometry.setFromPoints(meshPoints)
        customGeometry.computeVertexNormals()

        const mesh = new THREE.Mesh(customGeometry, customMaterial)

        if (toolData.smoothMode == false && toolData.useNormalMaterial == false)
        {
            // add just the edge lines we want 

            const bottomGeometry = new THREE.BufferGeometry().setFromPoints( bottomPoints );
            const bottomLine = new THREE.Line( bottomGeometry, new THREE.LineBasicMaterial( { color: 0x000000 } ));
            spineLines.push(bottomLine);

            const topGeometry = new THREE.BufferGeometry().setFromPoints( topPoints );
            const topLine = new THREE.Line( topGeometry, new THREE.LineBasicMaterial( { color: 0x000000 } ));
            spineLines.push(topLine);

            const upperBandGeometry = new THREE.BufferGeometry().setFromPoints( upperBandPoints );
            const upperBandLine = new THREE.Line( upperBandGeometry, new THREE.LineBasicMaterial( { color: 0x000000 } ));
            spineLines.push(upperBandLine);

            const outerEdgeGeometry = new THREE.BufferGeometry().setFromPoints( outerEdgePoints );
            const outerEdgeLine = new THREE.Line( outerEdgeGeometry, new THREE.LineBasicMaterial( { color: 0x000000 } ));
            spineLines.push(outerEdgeLine);

            const lowerBandGeometry = new THREE.BufferGeometry().setFromPoints( lowerBandPoints );
            const lowerBandLine = new THREE.Line( lowerBandGeometry, new THREE.LineBasicMaterial( { color: 0x000000 } ));
            spineLines.push(lowerBandLine);

            for(let i=0;i<spineLines.length;i++)
            {
                mesh.add(spineLines[i]);
            }
        }

        scene.add(mesh)             

        toolData.cylinderMesh = mesh;
    }
}
